import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import LayoutDark from "../shared/layout/layout-dark"
import SignUpFormComponent from "../shared/components/sign-up-form.component"

const routes = require("../../types/routes")

export const query = graphql`
  query SITE_INDEX_QUERY {
    allGhostPost(sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          id
          html
          title
          slug
          feature_image
          excerpt
          created_at(formatString: "DD MMMM YYYY")
          primary_author {
            name
            profile_image
          }
          primary_tag {
            name
          }
          reading_time
        }
      }
    }
    allGhostTag(sort: { order: ASC, fields: slug }) {
      edges {
        node {
          id
          name
          slug
          accent_color
        }
      }
    }
  }
`

class BlogComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      routes: routes,
      defaultQuery: [],
      tagQuery: [],
    }
  }

  componentWillMount() {
    const query = [...this.props.data.allGhostPost.edges]
    const tagQuery = [...this.props.data.allGhostTag.edges]
    this.setState({ defaultQuery: query, tagQuery: tagQuery })
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: Blog</title>
          <meta name="title" content="Contingent: Blog" />
          <link rel="canonical" href={`${process.env.BASE_URL}/blog/`} />
          <meta
            name="description"
            content="Read the latest news and stories on achieving operational resilience and proactively monitoring your supply chain."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`${process.env.BASE_URL}/blog/`} />
          <meta property="og:title" content="Contingent: Blog" />
          <meta
            property="og:description"
            content="Read the latest news and stories on achieving operational resilience and proactively monitoring your supply chain."
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`${process.env.BASE_URL}/blog/`}
          />
          <meta property="twitter:title" content="Contingent: Blog" />
          <meta
            property="twitter:description"
            content="Read the latest news and stories on achieving operational resilience and proactively monitoring your supply chain."
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
        </Helmet>

        <div className={"section-container blog"}>
          <div className={"section-info basic"}>
            <div className={"container blog-container"}>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"blog-card-container"}>
                    <Link
                      className={"blog-card-large"}
                      to={`/blog/${this.state.defaultQuery[0].node.slug}`}
                    >
                      <div
                        className={"image"}
                        style={{
                          backgroundImage:
                            "url('" +
                            this.state.defaultQuery[0].node.feature_image +
                            "')",
                        }}
                      />
                      <div className={"content"}>
                        <div className={"header"}>
                          <h6 className={"blog-tag"}>
                            {this.state.defaultQuery[0].node.primary_tag.name}
                          </h6>
                        </div>
                        <h2 className={"title"}>
                          {this.state.defaultQuery[0].node.title}
                        </h2>
                        <p className={"description"}>
                          {this.state.defaultQuery[0].node.excerpt}
                        </p>
                        <div className={"card-footer"}>
                          <div className={"author"}>
                            <div className={"group"}>
                              <img
                                className={"avatar"}
                                src={
                                  this.state.defaultQuery[0].node.primary_author
                                    .profile_image
                                }
                              />
                              <p className={"name"}>
                                {
                                  this.state.defaultQuery[0].node.primary_author
                                    .name
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"tags"}>
                    {this.state.tagQuery.slice(1).map(({ node }) => (
                      <a
                        href={`${process.env.BASE_URL}/blog/tags/${node.slug}`}
                        className={"blog-tag"}
                      >
                        {node.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"blog-card-container"}>
                    <div className={"blog-list"}>
                      {this.state.defaultQuery.slice(1).map(({ node }) => (
                        <Link
                          className={"blog-card"}
                          key={node.id}
                          to={`/blog/${node.slug}`}
                        >
                          <div
                            className={"image"}
                            style={{
                              backgroundImage:
                                "url('" + node.feature_image + "')",
                            }}
                          />
                          <div className={"content"}>
                            <div className={"header"}>
                              <h6 className={"blog-tag"}>
                                {node.primary_tag.name}
                              </h6>
                            </div>
                            <h4 className={"title"}>{node.title}</h4>
                            <p className={"description"}>{node.excerpt}</p>
                            <div className={"card-footer"}>
                              <div className={"author"}>
                                <div className={"group"}>
                                  <img
                                    className={"avatar"}
                                    src={node.primary_author.profile_image}
                                  />
                                  <p className={"name"}>
                                    {node.primary_author.name}
                                  </p>
                                </div>
                                <p className={"read-time"}>
                                  {node.reading_time} MIN READ
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}

export default BlogComponent
